import React from 'react';
import MaterialUITypography, { TypographyProperties } from '../Typography';

const Headline6 = ({ tag, ...properties }: TypographyProperties) => (
  <MaterialUITypography
    tag={tag}
    variant="h6"
    {...properties}
  />
);

export default Headline6;
