import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import MUIGrid, { GridProps as GridProperties } from '@mui/material/Grid';

type BaseGridProperties = Component.Properties<GridProperties>;

const BaseGrid = forwardRef<
HTMLElement, BaseGridProperties & StyledGridProperties
>(({
  tag = 'div', spacing = 4, outer, ...properties
}, reference) => (
  <MUIGrid
    container
    component={tag}
    spacing={spacing}
    ref={reference}
    {...properties}
  />
));

interface StyledGridProperties { outer?: boolean }

const Grid = styled(BaseGrid)<StyledGridProperties>`
  box-sizing: border-box;
  &&& {
    padding: ${({ outer }) => (outer ? '0 16px' : '0px')};
    margin: 0 auto 16px;
    width: 100%;
  }
`;

const BaseGridCell = forwardRef<
HTMLElement, BaseGridProperties & StyledGridCellProperties
>(({
  tag = 'div', xs = 12, minHeight, hideIntroTextOnMobile, ...properties
}, reference) => (
  <MUIGrid
    item
    component={tag}
    xs={xs}
    ref={reference}
    {...properties}
  />
));

interface StyledGridCellProperties {
  minHeight?: string; padding?: string; hideIntroTextOnMobile?: boolean;
}

const GridCell = styled(BaseGridCell)<
GridProperties & StyledGridCellProperties
>`
  &&&.MuiGrid-item {
    width: auto;
    ${({ container }) => (container ? 'min-width: calc(100% + 32px);' : '')}
    ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
    ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  }
`;

export {
  BaseGrid,
  Grid,
  GridCell,
};
